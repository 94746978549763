.img--responsive {
	max-width: 100%;
	height: auto;
	display: block;
}

.row--inner {
	padding-top: 20px;
	padding-bottom: 20px;
	@include small-desktop {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.row--inner-about {
	height: calc(550px + 160px);
	padding-bottom: 80px;
}

.row--panels {
	margin-left: -20px;
	margin-right: -20px;
}

.col--height {
	height: 100%;
	margin-bottom: 10px;
	@include small-desktop {
		margin-bottom: 0;
	}
}

/*
 * CUSTOM columns
 * col-xs-15,.col-xs-15,.col-sm-15,.col-md-15,.col-lg-15
 */

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}

.col-xs-15 {
	padding-left: 60px;
	padding-right: 60px;
	width: 100%;
	float: left;
}

@media (min-width: 480px) {
	.col-xs-15 {
		padding-left: 25px;
		padding-right: 25px;
		width: 50%;
		float: left;
	}
}

@media (min-width: 768px) {
	.col-sm-15 {
		padding-right: 10px;
		padding-left: 10px;
		width: 33.33333333%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-15 {
		padding-right: 10px;
		padding-left: 10px;
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-15 {
		padding-right: 10px;
		padding-left: 10px;
		width: 20%;
		float: left;
	}
}

.list {
	.list-title {
		padding: 25px 0;
		margin-bottom: 0;
	}
	.list-group-item {
		font-size: $font-base-size;
		padding: 15px 22px;
		width: 100%;
		display: inline-block;
		&:first-child, &:last-child {
			border-radius: 0;
		}
	}
}

@media (min-width: 1600px) {
	.container {
		width: 1220px;
	}
}