.navbar {
	background-color: transparent;
	border: 0;
	border-radius: 0;
	margin-top: 0;
	@include small-desktop {
		margin-top: 30px;
	}
	.container {
		padding: 0;
		@include small-desktop {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.nav-bg {
		background-color: $background-lightest;
		.navbar-header {
			@include tablet {
				float: none;
			}
			.navbar-brand {
				margin: 0;
				img {
					width: 85px;
				}
				@include small-desktop {
					padding: 30px 20px;
					display: none;
				}
			}
			.navbar-toggle {
				border: none;
				@include tablet {
					display: block;
				}
				&:hover, &:active, &:focus {
					background: none;
				}
			}
		}
		.navbar-collapse {
			@include tablet {
				display: none !important;
				&.in {
					display: block !important;
				}
			}
			.navbar-nav {
				float: none;
				margin: 0;
				@include small-desktop {
					float: left;
					width: calc(50% - 130px);
				}
				li {
					width: 100%;
					@include small-desktop {
						border-left: 1px solid #edf0f3;
						width: 33.3333%;
					}
					&:first-child {
						border: 0;
					}
					a {
						background-color: $background-lightest;
						color: $link;
						padding: 10px;
						text-align: center;
						font-size: $font-size-xs;
						font-weight: $bold;
						text-transform: uppercase;
						z-index: 1;
						@include small-desktop {
							padding: 25px 10px;
							&:hover {
								@include box-shadow(0, 1px, 8px, inset, rgba($shadow-dark-shade, .1));
							}
						}
						@include desktop {
							font-size: $font-base-size;
							padding: 25px 10px;
							&:hover {
								@include box-shadow(0, 1px, 8px, inset, rgba($shadow-dark-shade, .1));
							}
						}
					}
					&.selected {
						&:after {
							content: '';
							width: 20px;
							height: 20px;
							bottom: -10px;
							box-shadow: 0 0 10px rgba(#000, 0.2);
							background-color: #fff;
							transform: rotate(45deg);
							left: calc(50% - 14px);
							display: block;
							position: absolute;
							z-index: 0;
						}
						&:hover {
							&:after {
								@include box-shadow(0, 1px, 8px, inset, rgba($shadow-dark-shade, .1));
							}
						}
					}
				}
				&.navbar-left {
					float: none !important;
					@include small-desktop {
						border-right: 1px solid $border-light;
						float: left !important;
					}
				}
				&.navbar-right {
					float: none !important;
					margin-right: 0;
					@include small-desktop {
						border-left: 1px solid $border-light;
						float: right !important;
					}
				}
			}
			.logo-container {
				display: block;
				.navbar-brand {
					display: none;
				}
				.home-link {
					background-color: $background-lightest;
					color: $link;
					display: block;
					padding: 10px 10px 20px 10px;
					text-align: center;
					font-weight: $bold;
					text-transform: uppercase;
					z-index: 1;
				}
				@include small-desktop {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					-webkit-backface-visibility: hidden;
					z-index: 2;
					.navbar-brand {
						display: block;
						padding: 0;
						margin-left: 0;
						height: 170px;
						width: 170px;
						img {
							pointer-events: none;
							position: absolute;
							left: -50px;
							width: 270px;
						}
					}
					.home-link {
						display: none;
					}
				}
			}
		}
	}
}

.nav-mobile {
	margin-top: 10px;
	transition: all ease .5s;
	@include small-desktop {
		 margin-top: 30px;
	 }
	.container {
		padding-left: 10px;
		padding-right: 10px;
		.nav-bg {
			@include box-shadow(0, 1px, 4px, rgba($shadow-dark, .1), $inset: '');
		}
	}
}

.resized {
	@include small-desktop {
		height: 70px;
		transition: all 1s ease-in-out;
		.navbar-brand {
			height: auto;
			img {
				height: 100px;
				top: -6px;
			}
		}
	}
}