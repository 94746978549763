#home-slider {
	margin-top: 50px;
	@include small-desktop {
		margin-top: 0;
		&:after {
			content: '';
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 30%;
			width: 100%;
			z-index: 1;

			background: url(/img/layout/wave_slider_overlay.png) top no-repeat;
			background-size: cover;
		}
	}
	.slides {
		.slick-slide {
			height: auto;
			@include small-desktop {
				height: 700px;
			}
			@include desktop {
				height: 925px;
			}
		}
		.image-holder {
			height: auto;
			@include small-desktop {
				height: 100%;
			}
		}
		.slide-content {
			position: relative;
			@include small-desktop {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
			}

			.slide-text {
				top: 0;
				@include small-desktop {
					top: 120px;
				}
				@include desktop {
					top: 270px;
				}
				.inner {
					width: 100%;
					@include small-desktop {
						width: 400px;
					}

					.img--responsive {
						height: 60px !important; 
					}

					.title {
						font-size: $font-size-xl;
						text-transform: uppercase;
						font-weight: 800;
						@include small-desktop {
							font-size: 45px;
						}
					}

					.subtitle {
						font-size: 30px;
						text-transform: uppercase;
					}

					.btn {
						@extend .btn--ghost;
						border: 3px solid $link;
						&:hover {
							background: $background-button;
							color: $link-lightest;
						}
					}
				}
			}
		}
	}
}

#home-slider--bottom {
	display: none;
	@include small-desktop {
		display: block;
	}
	.slides {
		.slick-slide {
			height: 700px;
		}
		.slide-text {

			top: 150px;

			.btn--ghost {
				@include border(3px solid $blue-dark);
				color: $blue-dark;
				&:hover {
					background: $blue-dark;
					color: #FFF;
				}
			}
		}
	}
}

#gallery-holder {
	position: relative;
}

#gallery-slider {
	height: auto;
	margin-bottom: 0 !important;
	.slick-list {
		height: 100%;
	}
	.slick-slide {
		height: calc(100vh - 150px) !important;
		background-size: cover;
		background-position: center;
		img {
			opacity: 0;
		}
	}
	.slick-dots {
		margin: 0;
		position: absolute;
		text-align: center;
		width: 100%;
		bottom: 30px;
		right: 30px;
		li {
			margin: 0 5px;
			button {
				background: rgba($background-lightest, 0.4);
				margin: 3px;
				transition: .2s;
				width: 10px;
				height: 12px;
			}
			&.slick-active button {
				background: $background-lightest;
				margin: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	#gallery-slider {
		height: auto;
		.slick-slide img {
			height: 400px;
		}
	}
}

#gallery-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	font-weigh16pxt-size: $font-size-md;
	height: 481px;
	width: 561px;
	max-width: 100%;
	max-height: 100%;
	background: url('/img/layout/gallery_overlay.png') no-repeat left bottom;
	.inner {
		position: absolute;
		bottom: 30px;
		left: 30px;
		.filters {
			label {
				margin-right: 15px;
				margin-top: 4px;
				display: inline-block;
				font-weight: $regular;
				text-transform: uppercase;
			}
			.selectboxit-arrow-container {
				.icon-arrow-down {
					-webkit-transform: translate(-50%, -50%) rotate(90deg);
					transform: translate(-50%, -50%) rotate(90deg);
					&:before {
						content: "\EA01";
						font-family: "icons";
					}
				}
			}
		}
	}
}

#thumbnails {
	@include linear-gradient($background-light-shade, $background-lighter);
	padding: 10px 48px;
	.slick-slide {
		cursor: pointer;
		opacity: .5;
		transition: .2s;
		margin: 0 5px;
		overflow: hidden;
		text-align: center;
		&.slick-current {
			opacity: 1;
		}
		img {
			width: 100%;
		}
		.play-btn {
			display: block;
			height: 40px;
			width: 65px;
			background-color: #000000;
			border-radius: 5px;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			transition: .3s;
		}
		&:hover .play-btn {
			background-color: $icon;
		}
		.play-btn:after {
			content: "";
			display: block;
			position: absolute;
			top: 10.5px;
			left: 24px;
			margin: 0 auto;
			border-style: solid;
			border-width: 9.5px 0 9.5px 17px;
			border-color: transparent transparent transparent $border-light;
		}
	}
	.slick-prev, .slick-next {
		background-color: $background-lightest;
		@include box-shadow(0, 0, 10px, rgba($shadow-darker, .08), $inset: '');
		color: $icon;
		font-size: 18px;
		padding: 0 10px;
		height: 130px;
		width: 40px;
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}


