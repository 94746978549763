#googlemaps__canvas {
    width:100%;
    height:100%;
}
.google-maps {
    position:absolute;
    width:100%;
    height:100%;
}

.google-maps-container {
  height: 700px;
  position: relative;
  &:before {
	content: '';
	@include vertical-gradient(rgba($background-darkest-shade, .15), transparent);
	height: 7px;
	display: block;
	z-index: 1;
	position: absolute;
	top: 0;
	width: 100%;
  }
  &:after {
	bottom: 0;
	content: '';
	@include vertical-gradient(transparent, rgba($background-darkest-shade, .3) );
	height: 5px;
	display: block;
	z-index: 1;
	position: absolute;
	width: 100%;
  }
}

.googlemaps__overlay {
  background: rgba($background-lightest, .96);
  @include box-shadow(1px, 0, 4px, rgba($background-darkest, .15), $inset: '');
  left: 10px;
  padding: 30px;
  position: absolute;
  top: 80px;
  width: 350px;
  input {
	background: transparent;
	padding: 10px 5px;
	margin: 0 0 30px 0;
	width: 100%;
	border: none;
	border-bottom: 1px solid $border-light;
  }
  .btn {
	@extend .btn--ghost;
	border: 3px solid $link;
  }
  &:after {
	background: url("../img/layout/wave_bottom.png") no-repeat center;
	background-size: cover;
	bottom: -52px;
	content: '';
	display: block;
	height: 52px;
	left: 0;
	position: absolute;
	width: 350px;
  }
}