.template--contact {
	h3 {
		padding-top: 15px;
		padding-bottom: 15px;
		text-transform: uppercase;
		clear: both;
	}
	table {
		tbody {
			tr {
				td {
					color: $text-light;
					width: 100px;
					&:first-child {
						font-weight: $bold;
					}
				}
			}
		}
	}
	.field-input {
		float: left;
		margin-bottom: 10px;
		width: 50%;
	}
	input, textarea {
		background-color: $background-light;
		border: none;
		color: $text-lighter;
		padding: 20px 10px;
		width: 100%;
	}
	.name {
		padding-left: 0;
		padding-right: 7.5px;
	}
	.email {
		padding-left: 7.5px;
		padding-right: 0;
	}
	textarea {
		height: 130px;
	}
	.send_button {
		input {
			width: auto;
		}
	}
	.btn {
		border: 3px solid $border;
		float: right;
		padding: 0 100px;
		width: auto;
		@extend .btn--ghost;
		&:hover {
			background: $background-button;
			color: $link-lightest;
		}
	}
	.btn--contact {
		float: left;
		padding: 0 50px;
		@extend .btn;
		&:hover {
			background: $background-button;
			color: $link-lightest;
		}
	}
}

.error_text {
	color: $red;
}

.required_text {
	display: none;
}

.error {
	input, textarea {
		border: 1px solid $red !important;
	}
}