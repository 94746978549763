.template--info {
	.column-slider {
		.slick-slide {
			img {
				width: 100%;
			}
		}
		.slick-dots {
			margin: 0;
			position: absolute;
			text-align: left;
			width: 100%;
			top: 30px;
			left: 30px;
			li {
				margin: 0 5px;
				button {
					background: rgba($background-lightest, 0.4);
					margin: 3px;
					transition: .2s;
					width: 10px;
					height: 12px;
				}
				&.slick-active button {
					background: $background-lightest;
					margin: 0;
				}
			}
		}
		.slick-prev,
		.slick-next {
			border-radius: 40px;
			height: 40px;
			line-height: 40px;
			width: 40px;
			span {
				display: block;
				font-size: $font-size-xs;
				line-height: 13px;
			}
		}
	}
	.thumbnail-container {
		margin: 10px 0;
		.slick-slide {
			cursor: pointer;
			opacity: .5;
			transition: .2s;
			margin: 0 10px 0 0;
			overflow: hidden;
			text-align: center;
			&.slick-current {
				opacity: 1;
			}
		}
		.slick-prev, .slick-next {
			color: $icon;
			font-size: 18px;
			height: 130px;
			width: 50px;
		}
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
	}
}