html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html, body {
  padding: 0;
  margin: 0;
}

img {
  border: 0;
}

p {
  margin-top: 0;
  margin-bottom: 18px;
}

a {
  color: $link;
}

a, button {
  text-decoration: none;
  outline: none;
  transition: .2s;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul {
  margin-top: 0;
}

input:focus,
textarea:focus {
  outline: none;
}

figure {
  margin: 0;
}

iframe {
  border: 0;
}

.error-text {
  margin: 10px;
}

