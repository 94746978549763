footer {
	background: $background-dark;
	color: $text-lightest;
	h4 {
		color: $text-lightest;
		font-weight: 600;
		margin-bottom: 25px;
		text-transform: uppercase;
	}
	.footer__items {
		display: none;
		@include small-desktop {
			display: block;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: 13px;
			a {
				color: $text-lightest;
				font-size: $font-base-size;
				font-weight: 600;
				letter-spacing: 1.4px;
				text-transform: uppercase;
				font-family: "Source Sans Pro";
			}
			ul {
				li {
					&:before {
						content: "\EA01";
						font-family: 'icons';
						font-size: 8px;
						transform: rotate(180deg);
						display: inline-block;
						color: $text-lightest;
						vertical-align: middle;
						border: 2px solid rgba($border-lighter, .2);
						border-radius: 15px;
						line-height: 15px;
						margin-right: 7px;
						text-align: center;
						height: 20px;
						width: 20px;
					}
				}
			}
		}
	}
	.contact-info {
		width: 100%;
		table {
			color: $text-light;
			tr:first-child {
				padding-right: 30px;
			}
			a {
				color: $text-light;
			}
		}
	}
	.footer__newsletter {
		background-color: $background-darkest;
		.newsletter {
			color: $text-lightest;
			display: block;
			position: relative;
			@include small-desktop {
				margin: 0 auto;
				width: 85%;
			}
			.title {
				float: left;
				//margin-right: 30px;
				text-transform: uppercase;
				display: inline-block;
				width: 100%;
				@include small-desktop {
					height: 65px;
					width: 33.33333333%;
				}
				h4 {
					font-size: $font-base-size;
					margin-bottom: 10px;
					@include small-desktop {
						line-height: 65px;
						margin-bottom: 25px;
					}
					@include desktop {
						font-size: $font-size-md;
					}
				}
			}
			.webform {
				float: left;
				width: 100%;
				@include small-desktop {
					width: 66.66666666%;
				}
				.email, .send_button {
					display: inline-block;
					float: left;
					padding: 10px 0;
					width: 100%;
					@include small-desktop {
						padding: 0 10px;
						width: 50%;
					}
					input {
						background-color: $background-dark;
						border: none;
						color: $text-lightest;
						float: left;
						font-size: $font-size-xs;
						height: 65px;
						margin-top: 0;
						margin-right: 30px;
						padding: 20px;
						@include small-desktop {
							width: 100%;
						}
						&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
							color: $text-lightest;
						}
					}
				}
				.btn {
					border: 3px solid #ff6400;
					display: inline-block;
					color: #ff6400;
					float: left;
					height: 65px;
					line-height: 60px;
					text-align: center;
					padding: 0;
					width: 100%;
					&:hover {
						background: #ff6400;
						color: $link-lightest;
					}
				}
				.required_text {
					display: none;
				}
			}
			.webform-submit-classic {
				display: none;
			}
		}
	}
	.footer__bottom {
		background-color: $background-darkest;
		border-top: 2px solid $border-darkest;
		font-size: $font-size-xs;
		text-align: center;
		.row--inner {
			padding-top: 15px;
			padding-bottom: 15px;
			p {
				float: none;
				margin-bottom: 0;
				@include small-desktop {
					float: left;
				}
			}
			a {
				color: $link-lightest;
				display: block;
				float: none;
				padding-left: 15px;
				padding-right: 15px;
				@include small-desktop {
					float: left;
				}
			}
			.to-top {
				float: right;
				.icon-arrow {
					&:before {
						font-size: 9px;
						padding: 0 17px;
						transform: rotate(90deg);
					}
				}
			}
			.footer__social {
				display: inline-block;
				text-align: center;
				a {
					padding: 15px;
					float: left;
					@include small-desktop {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
				.icon {
					color: $icon-light;
				}
			}
		}
	}
}