#home-template {
	.event-section {
		.list {
			.list__title {
				padding-bottom: 25px;
				margin-bottom: 0;
				text-transform: uppercase;
			}
			.list__group {
				@include border-all(2px, solid, $border-light);
				padding-left: 0;
				.list__group--item {
					@include border-all(1px 0 0 0, solid, $border-light);
					display: inline-block;
					padding: 0;
					vertical-align: middle;
					-webkit-transform: translate(0);
					transform: translate(0);
					box-shadow: 0 0 1px rgba(0, 0, 0, 0);
					-webkit-backface-visibility: hidden;
					backface-visibility: hidden;
					-moz-osx-font-smoothing: grayscale;
					position: relative;
					transition: 0.3s;
					text-transform: uppercase;
					height: calc(405px / 6);
					width: 100%;
					z-index: 1;
					&:first-child {
						border-top: none;
					}
					&:after {
						box-shadow: 0 5px 10px rgba(4, 31, 67, 0.1);
						content: "";
						position: absolute;
						z-index: -1;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						@include linear-gradient($gradient-darkest, $gradient-dark);
						transform: scale(0);
						transform-origin: 50%;
						transition-property: all;
						transition-duration: 0.3s;
						transition-timing-function: ease;
						height: 100%;
						width: 100%;
						opacity: 0;
					}
					&:hover, &:focus, &:active {
						background-color: transparent;
						color: $text-lightest;
						z-index: 2;
					}
					&:hover, &:focus, &:active {
						.icon, .group--item__title {
							color: $text-lightest;
						}
					}
					&:hover:after, &:focus:after, &:active:after {
						box-shadow: 0 5px 10px rgba(4, 31, 67, 0.1);
						-webkit-transform: scale(1.05);
						transform: scale(1.05);
						opacity: 1;
					}
					.icon {
						color: $icon-light-shade;
						display: inline-block;
						font-size: 29px;
						padding: 10px 0;
						position: relative;
						width: 40px;
						transition: .2s color;
						&:before {
							position: absolute;
							top: 0;
						}
					}
					.group--item__title {
						@include border-all(0 0 0 1px, solid, $border-light);
						color: $text-light;
						padding: 10px 20px;
						transition: .2s color;
					}

					a {
						display: block;
						padding: 20px;
					}
				}
			}
		}
	}

	.rent-section {
		background: url("../img/layout/rent_background_wave.png") no-repeat center;
		background-size: cover;
		@include desktop {
			height: 522px;
		}
		.panels {
			display: inline-block;
			width: 100%;
			.panels__title {
				text-align: center;
				.title {
					text-transform: uppercase;
					padding-bottom: 30px;
					font-size: 18px;
					@include small-desktop {
						font-size: $font-size-lg;
					}
				}
			}
			.panel {
				padding-top: 15px;
				padding-bottom: 15px;
				@include small-desktop {
					padding-top: 0;
					padding-bottom: 0;
				}
				.panel__header {
						display: block;
					.img-circle {
						border: 5px solid $border-lightest;
						border-radius: 50%;
						box-shadow: 0 5px 10px rgba(4, 31, 67, 0.1);
						height: auto;
						margin-bottom: 40px;
						width: 100%;
					}
				}
				.panel__footer {
					display: inline-block;
					padding-left: 15px;
					padding-right: 15px;
					width: 100%;
				}
			}
		}
	}

	.about-section {
		.weather-forecast {
			@include border-all(2px, solid, $border-light);
			display: block;
			height: 100%;
			.weather-forecast__title {
				height: 75px;
				padding: 22.5px 30px;
				margin-bottom: 0;
				text-transform: uppercase;
			}
			.weather-forecast__info {
				list-style: none;
				margin: 0;
				padding: 0;
				height: calc(100% - 75px);
				width: 100%;
				li {
					display: block;
					height: calc(100% / 7);
					padding: 0 30px;
					@include border-all(1px 0 0 0, solid, $border-light);
					color: $text-light;
					width: 100%;
					&:first-child {
						& > span {
							text-align: center;
							width: 33.33333%;
						}
					}
					& > span {
						position: relative;
						top: 50%;
						display: block;
						float: left;
						-webkit-transform: translateY(-50%);
						transform: translateY(-50%);
					}
					.icon {
						font-size: $font-size-xl;
						color: $icon-light-shade;
						width: 40px;
						text-align: center;
						margin-right: 15px;
						&:before {
							font-family: 'weathericons';
						}
						&.wi-thermometer {
							font-size: 25px;
						}
						&.date {
							float: right;
						}
					}
					.btn {
						position: relative;
						top: 50%;
						-webkit-transform: translateY(-50%);
						transform: translateY(-50%);
						width: 100%;
					}
				}
			}
		}
		.about {
			height: 100%;
			.about__header {
				background: no-repeat center center;
				background-size: cover;
				height: calc(50% - 45px);
			}
			.about__content {
				background-color: $background-lightest;
				border: 2px solid $border-light;
				height: calc(50% + 45px);
				position: relative;
				.content-inner {
					padding: 30px;
					.content-inner__title {
						padding-bottom: 20px;
						text-transform: uppercase;
					}
					p {
						color: $text-light;
					}
				}
				.btn--arrow-btm {
					@include border-all(1px 0 0 0, solid, $border-light);
				}
			}
		}
		.map-col {
			height: 100%;
			overflow: hidden;
			.map-col__map {
				position: relative;
				height: 100%;
			}
			.map-col__footer {
				position: relative;
				.btn--arrow-btm {
					@include box-shadow(0, 0, 20px, rgba($shadow-darkest, .45), $inset: '');
				}
			}
		}
	}
}
