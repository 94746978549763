#image-header {
	height: auto;
	margin-top: 50px;
	@include small-desktop {
		background-position: center center;
		background-size: cover;
		height: 700px;
		margin-top: 0;
		width: 100%;
	}
	img {
		display: block;
		width: 100%;
		@include small-desktop {
			display: none;
		}
	}
}

.template {
	background-color: $background-lightest;
	padding-bottom: 80px;
	.content {
		background-color: $background-lightest;
		@include box-shadow(0, 0, 10px, rgba($shadow-darkest, .08), $inset: '');
		display: inline-block;
		margin-top: 10px;
		width: 100%;
		@include tablet {
			margin-top: -70px;
		}
		@include small-desktop {
			margin-top: -205px;
		}
		.content__header {
			border-bottom: 1px solid $border-light;
			padding: 10px 0;
			text-align: center;
			width: 100%;
			@include small-desktop {
				min-height: 100px;
				padding: 30px 0;
			}
			.path {
				ul {
					padding-left: 0;
					margin: 0;
					li {
						font-size: $font-size-lg;
						list-style: none;
						text-transform: lowercase;
						.slash {
							color: $text-light;
						}
						a {
							line-height: 25px;
						}
						h1 {
							color: $text-dark;
							line-height: 30px;
							padding: 10px 0;
							text-transform: uppercase;
						}
					}
				}
			}
		}
		.content__body {
			display: inline-block;
			padding: 40px 20px;
			width: 100%;
			@include small-desktop {
				padding: 70px 30px;
			}
			.subtitle {
				padding: 10px 0;
				@include small-desktop {
					padding: 0;
				}
			}
			.desc {
				padding-bottom: 30px;
				margin: 0 auto;
				width: 80%;
				.subtitle {
					font-weight: $regular;
					text-transform: none;
				}
			}
			h1, h2, h3, h4, h5, h6, .subtitle {
				color: $text-dark;
				font-weight: 600;
				text-transform: uppercase;
				strong {
					font-weight: bold;
				}
			}
			p {
				color: $text-light;
			}

			.btn--content {
				float: left;
				margin-top: 20px;
				padding: 0 50px;
				border: 3px solid $border;
				width: auto;
				@extend .btn;
				@extend .btn--ghost;
				&:hover {
					background: $background-button;
					color: $link-lightest;
				}
			}
		}
	}
}


