body {
  background-color: $background-lightest;
  color: $text-light;
  font-family: $font-base-family;
  font-size: $font-base-size;
  font-weight: $regular;
}

h1, h2, h3, h4, h5, h6 {
  color: $text-dark;
  font-family: $font-heading-bold;
  font-weight: $bold;
}

h1 {
  font-size: $font-size-xl;
}

h2 {
  font-size: $font-size-lg;
}

h3 ,h4 {
  font-size: $font-size-md;
}

