.card__inner, .list__group {
	height: 409px;
}

.card {
	margin-bottom: 20px;
	.card__title {
		padding-bottom: 25px;
		margin-bottom: 0;
		text-transform: uppercase;
	}
	.card__tbn {
		height: 50%;
		overflow: hidden;
		position: relative;
		.tbn {
			background: no-repeat center center;
			background-size: cover;
			display: block;
			height: 100%;
			width: 100%;
		}
		.triangle {
			position: absolute;
			bottom: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 13.5px 13px 13.5px;
			border-color: transparent transparent #ffffff transparent;
			margin-left: calc(50% - 13px);
		}
	}
	.card__content {
		border-width: 0 2px 2px 2px;
		border-style: solid;
		border-color: $border-light;
		color: $text-light;
		height: 50%;
		padding: 30px;
		.title {
			padding-bottom: 40px;
			text-transform: uppercase;
		}
		.content-desc {
			height: 126px;
		}
	}
}

.inner--overview {
	.card__inner {
		height: 350px;

		.card__tbn {
			height: 60%;
		}
		.card__content {
			height: 40%;
			.title {
				height: 60px;
				padding: 0;
			}
		}
	}
}