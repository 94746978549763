.btn {
	vertical-align: middle;
	font-family: "Source Sans Pro";
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	text-align: center;
	width: 100%;
	@include desktop {
		font-size: $font-base-size;
	}
}

.btn--ghost {
	display: block;
	height: 65px;
	line-height: 60px;
	float: left;
	font-size: 12px;
	@include desktop {
		font-size: $font-base-size;
	}
}

.btn--transition {
	border: 2px solid transparent;
	-moz-border-image: -moz-linear-gradient(left, #2cc0c4 0%, #80d9dc 100%);
	-webkit-border-image: -webkit-linear-gradient(left, #2cc0c4 0%, #80d9dc 100%);
	border-image: linear-gradient(to right, #2cc0c4 0%, #80d9dc 100%);
	border-image-slice: 1;
	color: $link-light;
	font-size: $font-size-xs;
	font-weight: $regular;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
	height: 55px;
	line-height: 50px;
	text-align: center;
	transition: all .4s ease-in-out;
	z-index: 1;
	@include small-desktop {
		font-size: 12px;
	}
	@include desktop {
		font-size: $font-base-size;
	}
	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $background-lightest;
		border: 3px solid $border-lightest;
		transform: scale(0);
		transform-origin: 50%;
		transition-property: all;
		transition-duration: 0.3s;
		transition-timing-function: ease;
		height: 100%;
		width: 100%;
		opacity: 0;
	}
	&:hover, &:focus, &:active {
		background-color: transparent;
		z-index: 2;
	}
	&:hover:after, &:focus:after, &:active:after {
		@include box-shadow(0, 5px, 10px, rgba($shadow-darker, .1), $inset: '');
		-webkit-transform: scaleY(1.2) scaleX(1.1);
		transform: scaleY(1.2) scaleX(1.1);
		opacity: 1;
	}
}

.btn--arrow {
	display: inline-block;
	height: 18px;
	line-height: 18px;
	text-align: left;
	&:after {
		border-right: 1px solid $border-light-shade;
		content: "\EA01";
		display: block;
		font-family: 'icons';
		font-size: $font-base-size;
		float: right;
		height: 100%;
		line-height: 15px;
		margin-right: 7px;
		padding-right: 9px;
		transform: rotate(180deg);
	}
}

.btn--filled {
	background-color: $background-lightest;
}

.btn--arrow-btm {
	bottom: 0;
	height: 66px;
	padding: 25px 30px;
	position: absolute;
	display: inline-block;
	line-height: 18px;
	text-align: left;

	&:after {
		@extend .btn--arrow:after;
	}
}

.btn--arrow-border {
	@include border-all(1px 2px 2px 2px, solid, $border-light);
}

.btn--c2a {
	bottom: 100px;
	display: none;
	height: 130px;
	right: 100px;
	width: 315px;
	position: absolute;
	z-index: 2;
	@include small-desktop {
		display: block;
	}
	.btn--c2a__contact {
		background: url(/img/layout/contact_c2a_btn.png) center no-repeat;
		height: 100%;
		width: 100%;
		a {
			color: $link-lightest;
			font-size: 18px;
			position: absolute;
			left: 60px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			.icon {
				float: left;
				font-size: 35px;
				height: 45px;
				width: 60px;
			}
			.content {
				border-left: 1px solid $border-lightest;
				float: left;
				line-height: 20px;
				height: 40px;
				padding-right: 15px;
				width: 180px;
			}
		}
	}
}

.btn--goto {
	display: none;
	@include small-desktop {
		background-color: rgba($background-darkest, .6);
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		bottom: 0;
		margin-left: calc(50% - 50px);
		height: 50px;
		text-align: center;
		width: 100px;
		z-index: 2;
		display: block;
		position: absolute;
	}
	.btn--goto__bottom {
		color: $link-lightest;
		display: block;
		line-height: 50px;
		height: 50px;
		width: 100px;
		.icon-arrow {
			&:before {
				font-size: $font-size-lg;
				line-height: 65px;
				transform: rotate(-90deg);
			}
		}
	}
}