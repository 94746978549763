@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin linear-gradient($left, $right){
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left, right, color-stop(0%,$left), color-stop(100%,$right)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $left 0%,$right 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $left 0%,$right 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $left 0%,$right 100%); /* IE10+ */
  background: linear-gradient(to right, $left 0%,$right 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin vertical-gradient($top, $bottom){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, top, bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin box-shadow($top: 2px, $left: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: '') {
  @if ($inset != '') {
    @include prefix('box-shadow', $inset $top $left $blur $color);
  } @else {
    @include prefix('box-shadow', $top $left $blur $color);
  }
}

@mixin border($border) {
  border: $border;
}

@mixin border-all($width, $style, $color) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
}

$tablet-width: 768px;
$smaller-desktop-width: 1024px;
$desktop-width: 1280px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$smaller-desktop-width - 1px}) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: #{$smaller-desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}