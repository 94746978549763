.webform {
	::-webkit-input-placeholder {
		color: $text-light;
	}
	:-moz-placeholder {
		color: $text-light;
	}
	::-moz-placeholder {
		color: $text-light;
	}
	:-ms-input-placeholder {
		color: $text-light;
	}

	.field-input {
		float: left;
		padding-right: 7.5px;
		padding-left: 7.5px;
		margin-bottom: 10px;
		width: 50%;
	}
	.wf-group-title {
		display: inline-block;
		font-weight: $bold;
		padding: 15px 0;
		width: 100%;
	}
	.selectboxit-container {
		width: 100%;
		span {
			height: 57px;
			line-height: 20px;
		}
		.selectboxit {
			background-color: $background-light;
			border: none;
			padding: 20px 10px;
			color: $text-lighter;
			width: 100%;
		}
		.selectboxit-arrow-container {
			top: 0;
			width: 45px;
			.icon-arrow-down {
				left: 0;
				float: right;
				font-size: 14px;
				-webkit-transform: translate(-50%, -50%) rotate(-90deg);
				transform: translate(-50%, -50%) rotate(-90deg);
				&:before {
					content: "\EA01";
					font-family: "icons";
				}
			}
		}
		.selectboxit-options {
			.selectboxit-option {
				a {
					height: auto;
					padding: 10px 15px;
				}
				.selectboxit-option-icon-container {
					display: none;
					float: none;
				}
			}
		}
	}

	input, textarea {
		background-color: $background-light;
		border: none;
		color: $text-lighter;
		padding: 20px;
		font-size: $font-base-size;
		width: 100%;
	}
	textarea {
		height: 130px;
	}
	.required_text {
		display: inline-block;
		padding: 15px 0;
		width: 100%;
	}
	.send_button {
		input {
			width: auto;
		}
		.btn {
			border: 3px solid $border;
			float: left;
			padding: 0 100px;
			width: auto;
			@extend .btn--ghost;
			&:hover {
				background: $background-button;
				color: $link-lightest;
			}
		}
	}
}