.slider {
	position: relative;
	.slides {
		.slick-slide {
			.image-holder {
				background-size: cover;
				background-position: center;
				height: 100%;
				img {
					opacity: 0;
					width: 100%;
					height: auto;
				}
			}
			.slide-content {
				position: relative;
				@include small-desktop {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
				}
				.container {
					height: 100%;
					position: relative;
					.slide-text {
						position: relative;
						top: 0;
						width: 100%;
						z-index: 10;
						@include small-desktop {
							top: 270px;
						}
						.inner {
							display: inline-block;
							background: rgba($background-lightest, .96);
							padding: 20px;
							width: 100%;
							@include small-desktop {
								padding: 50px;
								@include box-shadow(1px, 0, 4px, rgba($background-darkest, .15), $inset: '');
								width: 400px;
								&:after {
									background: url("../img/layout/wave_bottom.png") no-repeat center;
									background-size: cover;
									bottom: -52px;
									content: '';
									display: block;
									height: 52px;
									left: 0;
									position: absolute;
									width: 400px;
								}
							}
							p {
								color: $text-light;
								font-size: $font-base-size;
								position: relative;
								padding: 10px 0;
								margin-bottom: 0;
								@include small-desktop {
									padding: 25px 0;
									margin-bottom: 27px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.slick-prev,
.slick-next {
	background: $background-lightest;
	@include box-shadow(0, 0, 8px, rgba($shadow-darker, .15), $inset: '');
	color: $icon;
	border-radius: 60px;
	height: 60px;
	padding: 0;
	width: 60px;
	display: none !important;
	@include small-desktop {
		display: block !important;
	}
	span {
		display: block;
		font-size: 22px;
		&:before {
			content: "\EA01";
			display: block;
			font-family: "icons";
		}
	}
}

.slick-next {
	span {
		line-height: 22px;
		&:before {
			transform: rotate(180deg);
		}
	}
}

.dots-container {
	position: relative;
	width: 100%;
	z-index: 3;
	.slick-dots {
		margin: 0;
		position: absolute;
		text-align: left;
		width: 100%;
		bottom: 680px;
		li {
			margin: 0 5px;
			button {
				background: rgba($background-lightest, 0.4);
				margin: 3px;
				transition: .2s;
				width: 10px;
				height: 12px;
			}
			&.slick-active button {
				background: $background-lightest;
				margin: 0;
			}
		}
	}
}
