// Colors
$white: #fff;
$black: #000;

$grey-shade: #5f6266;
$grey-dark: #2b2728;
$grey-darker: #1e1b1c;
$grey-darkest: #12110e;
$grey-light: #94a0ac;
$grey-light-shade: #c0c2c2;
$grey-lighter: #e7eaed;
$grey-lighter-shade: #eef2f7;
$grey-lightest: #edf0f3;
$grey-lightest-shade: #eff1f4;

$blue: #1ba7e9;
$blue-dark: #002ea8;
$blue-darkest: #041f43;
$blue-darkest-shade: #041530;
$blue-light: #2cc0c4;
$blue-lighter: #61c7ca;
$blue-lightest: #dfe5ec;

$green: #4d5a2f;

$red: #eb2117;

// Font weights
$light: 100;
$regular: 400;
$bold: 600;

// Font size
$font-size-xl: 30px;
$font-size-lg: 21px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 13px;

// Base Font
$font-base-family: "Open Sans", Arial, Helvetica, sans-serif;
$font-base-weight: $regular;
$font-base-size: $font-size-sm;

// Heads (DinPro bold, medium, regular)
$font-heading-bold: "Open Sans", Arial, Helvetica, sans-serif;
$font-heading-medium: "Open Sans", Arial, Helvetica, sans-serif;
$font-heading-regular: "Open Sans", Arial, Helvetica, sans-serif;

// Background
$background-light: $grey-lighter;
$background-light-shade: $grey-lighter-shade;
$background-lighter: $grey-lightest-shade;
$background-lightest: $white;
$background-dark: $grey-dark;
$background-darkest: $grey-darker;
$background-darkest-shade: $black;

// Buttons
$background-button: $blue-dark;
$background-button-green: $green;

// Gradient
$gradient-light: $blue;
$gradient-lightest: $blue;
$gradient-dark: $blue;
$gradient-darkest: $blue-dark;

// Box shadow
$shadow-dark: $blue-darkest;
$shadow-dark-shade: $blue-darkest-shade;
$shadow-darker: $grey-darker;
$shadow-darkest: $black;

// Borders
$border: $blue-dark;
$border-green: $green;
$border-dark: $blue-lighter;
$border-darker: $blue-light;
$border-darkest: $grey-dark;
$border-light: $grey-lighter;
$border-light-shade: $grey-lightest;
$border-lighter: $blue-lightest;
$border-lightest: $white;

// Highlight
$highlight: $blue-dark;

// Links
$link: $blue-dark;
$link-green: $green;
$link-dark: $grey-shade;
$link-light: $blue-light;
$link-lightest: $white;

// Text
$text: $blue-dark;
$text-dark: $grey-dark;
$text-light: $grey-shade;
$text-lighter: $grey-light;
$text-lightest: $white;

// Icon colors
$icon: $blue-dark;
$icon-light: $grey-shade;
$icon-light-shade: $grey-light-shade;